/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box } from 'rebass'

const Pill = (props) => {
    return (
        <Box as="span" {...props}
            sx={{
                py: 1,
                px: 3,
                fontSize: '0.7em',
                borderRadius: 'full',
                textAlign: 'center',
                height: '24px',
                "&.water": {
                    backgroundColor: 'blue.4',
                    color: 'white'
                },
                "&.wastewater": {
                    backgroundColor: 'yellow.4',
                    color: 'black'
                },
            }} />
    )
}

export default Pill