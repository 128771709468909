/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { Box, Flex } from 'rebass'
import styled from '@emotion/styled'

import { LinkExt } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { Container, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import Logo from '../elements/Logo'

const FooterList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    a {
        text-decoration: none;
    }
    a:hover {
        color: white;
    }
`

const Footer = () => {

    const d = new Date()
    const n = d.getFullYear()

    return (
        <Section sx={{
            backgroundColor: 'gray.8',
            color: 'gray.4',
            pb: 0
        }}>
            <Container>
                <Flex flexWrap="wrap" pb={4}>
                    <Box width={['full', 'full', 1 / 4]} mb={[4, 4, 0]}>
                        <Link to="/" aria-label="Pub Logo">
                            <Logo sx={{ display: 'block', fill: 'gray.3', width: 'fit-content', ".logo_text": { display: 'none' } }} />
                        </Link>
                    </Box>

                    <Box width={['full', 1 / 3, 1 / 4]}>
                        <FooterList>
                            <li><Link to="about" sx={{ color: 'gray.2' }}>About</Link></li>
                            <li><Link to="customer-service" sx={{ color: 'gray.2' }}>Customer Service</Link></li>
                            <li><Link to="faqs" sx={{ color: 'gray.2' }}>FAQs</Link></li>
                        </FooterList>
                    </Box>

                    <Box width={['full', 1 / 3, 1 / 4]}>
                        <FooterList>
                            <li><Link to="services" sx={{ color: 'gray.2' }}>Services</Link></li>
                            <li><Link to="projects" sx={{ color: 'gray.2' }}>Projects</Link></li>
                            <li><Link to="careers" sx={{ color: 'gray.2' }}>Careers</Link></li>
                        </FooterList>
                    </Box>

                    <Box width={['full', 1 / 3, 1 / 4]}>
                        <FooterList>
                            <li><Link to="notices" sx={{ color: 'gray.2' }}>Notices</Link></li>
                            <li><Link to="contact" sx={{ color: 'gray.2' }}>Contact</Link></li>
                            <li><Link to="terms" sx={{ color: 'gray.2' }}>Terms and Conditions</Link></li>
                        </FooterList>
                    </Box>
                </Flex>
            </Container>
            <Box width="full" >
                <Container>
                    <Flex flexWrap="wrap" sx={{ borderTop: '1px solid', borderColor: 'gray.7', py: 2 }}>
                        <Box width={['full']} sx={{ textAlign: ['left', 'center', 'center'] }}>
                            &copy; {n} Copyright - Parkersburg Utility Board &nbsp;•&nbsp;
                            Website Design & Development: <LinkExt to="https://www.mindmergedesign.com" sx={{ color: 'gray.2' }}>Mind Merge Design</LinkExt>
                        </Box>
                    </Flex>
                </Container>
            </Box>
        </Section>
    )
}

export default Footer
