/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { Box, Flex } from 'rebass'
import { MdChevronRight, MdCreditCard, MdCheckCircle, MdCancel, MdPerson } from "react-icons/md";

const LinkNav = (props) =>
    <Box
        {...props}
        sx={{
            fontSize: 1,
            listStyle: 'none',
            a: {
                backgroundColor: 'primary',
                border: '1px solid rgba(0,0,0,0.1)',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 3,
                textDecoration: 'none',
                width: '100%',
                transition: 'background-color .25s',
                '&:hover': {
                    backgroundColor: 'primaryHover',
                    transform: 'scale(1.05)',
                    transition: 'all 200ms ease-in'
                }
            }
        }}
    />


const QuickLinks = () => {
    return (
        <Flex flexWrap="wrap" justifyContent="center" as="ul">
            <LinkNav as="li" width={['full', 1 / 2, 1 / 4]}>
                <Link to="customer-service/account/pay-bill"><MdCreditCard sx={{ fontSize: '5', fill: 'blue.4' }} /> Pay My Bill <MdChevronRight sx={{ fontSize: 3 }} /></Link>

            </LinkNav>
            <LinkNav as="li" width={['full', 1 / 2, 1 / 4]}>
                <Link to="customer-service/account/start-service"><MdCheckCircle sx={{ fontSize: '5', fill: 'blue.4' }} />Start Service <MdChevronRight sx={{ fontSize: 3 }} /></Link>

            </LinkNav>
            <LinkNav as="li" width={['full', 1 / 2, 1 / 4]}>
                <Link to="customer-service/account/end-service"><MdCancel sx={{ fontSize: '5', fill: 'blue.4' }} />End Service <MdChevronRight sx={{ fontSize: 3 }} /></Link>

            </LinkNav>
            <LinkNav as="li" width={['full', 1 / 2, 1 / 4]}>
                <Link to="customer-service/account"><MdPerson sx={{ fontSize: '5', fill: 'blue.4' }} />My Account <MdChevronRight sx={{ fontSize: 3 }} /></Link>
            </LinkNav>
        </Flex>
    )
}

export default QuickLinks
