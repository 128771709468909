import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Helmet from 'react-helmet'

const SEO = (props) => {

  return (
    <StaticQuery
      query={graphql`
      {
        site {
          siteMetadata {
            backgroundColor
            ogLanguage
            ogSiteName
            siteDescription
            siteLanguage
            siteLogo
            siteShortName
            siteTitle
            siteTitleAlt
            siteUrl
            themeColor
            title
          }
        }
      }
    `}

      render={data =>
        <Helmet>
          <html lang={data.site.siteMetadata.siteLanguage} />
          <title>{props.meta_title ? `${props.meta_title} | ${data.site.siteMetadata.siteTitle}` : `${data.site.siteMetadata.siteTitle}`}</title>
          <meta name="msapplication-TileColor" content={data.site.siteMetadata.backgroundColor} />
          <meta name="msapplication-config" content="browserconfig.xml" />
          <meta name="description" content={props.meta_description ? `${props.meta_description}` : `${data.site.siteMetadata.siteDescription}`} />
          <meta property="og:locale" content={data.site.siteMetadata.siteLanguage} />
          <meta property="og:site_name" content={data.site.siteMetadata.siteTitle} />
          <meta property="og:title" content={data.site.siteMetadata.siteTitle} />
          <meta property="og:description" content={data.site.siteMetadata.siteDescription} />
        </Helmet>
      }></StaticQuery>
  )
}

export default SEO