/** @jsx jsx */
import { jsx } from 'theme-ui'
import { StaticQuery, graphql } from "gatsby"
import { Box } from 'rebass'
import BackgroundImage from 'gatsby-background-image'

import { Container } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { Hero, Overlay } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'

const PubHero = (props) => (
  <StaticQuery
    query={graphql`
    {
      prismic {
        home(lang: "en-us", uid: "home") {
          hero_imageSharp {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          hero_image
        }
      }
    }
  `}
    render={data =>
      (<Hero>
        <BackgroundImage Tag="div" fluid={data.prismic.home.hero_imageSharp.childImageSharp.fluid}>
          <Container>
            <Box sx={{
              display: 'block',
              position: 'relative',
              py: [5, 5, 6],
              textAlign: 'center',
              zIndex: 5,
            }}>
              <h1>{props.title}</h1>
            </Box>
          </Container>
          <Overlay bg="gray.8" opacity="0.5" />
        </BackgroundImage>
      </Hero>)
    }>
  </StaticQuery>
)


export default PubHero

