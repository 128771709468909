/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Footer, Header } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { Global, css } from '@emotion/core';
import { slide as Menu } from 'react-burger-menu'
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav'

import theme from '../../../../gatsby-plugin-theme-ui'
import { Nav } from '../../../../components/elements'

export default props => {

    const breakpoints = [640, 758, 1024, 1280]

    const mq = breakpoints.map(
        bp => `@media (min-width: ${bp}px)`
    )

    return (
        <React.Fragment>
            <Global
                styles={css`
                    html {
                        box-sizing: border-box;
                        margin: 0;
                        border: 0;
                    }
                    *,
                    *:before,
                    *:after {
                        box-sizing: inherit;
                    }
                    a {
                        color: ${theme.colors.link};
                        &:hover{
                            color: ${theme.colors.linkHover};
                            transition: color .25s;
                        }
                    }
                    h1 {
                        font-size: ${theme.fontSizes[5]};
                        ${mq[1]} {
                            font-size: ${theme.fontSizes[6]};
                        }
                    }
                    h2 {
                        font-size: ${theme.fontSizes[3]};
                        ${mq[1]} {
                            font-size: ${theme.fontSizes[4]};
                        }
                    }
                    h3 {
                        font-size: ${theme.fontSizes[2]};
                        ${mq[1]} {
                            font-size: ${theme.fontSizes[3]};
                        }
                    }
                    h4 {
                        font-size: ${theme.fontSizes[1]};
                        ${mq[1]} {
                            font-size: ${theme.fontSizes[2]};
                        }
                    }
                    a span.button{
                        background-color: ${theme.colors.primary};
                        color: ${theme.colors.white};
                        font-weight: bold;
                        padding: 1em 3em;
                        &:hover {
                            background-color: ${theme.colors.primaryHover};
                        }
                    }
                    table{
                        max-width: 100%;
                    }
                    thead{
                        font-weight: bold;
                    }
                    .text-lg{
                        font-size: ${theme.fontSizes[2]};
                    }
                    .text-sm{
                        font-size: ${theme.fontSizes[0]};
                    }
                    [data-reach-skip-link] { 
                        background: white;
                        left: -99999em;
                        padding: 0.5em;
                        position: absolute;
                        z-index: 10; 
                    }
                    [data-reach-skip-link]:focus { left: 0.5em; top: 0.5em }

                     /* Position and sizing of burger button */
                    .bm-burger-button {
                        position:absolute;
                        width: 30px;
                        height: 22px;
                        right: 1.25em;
                        top: 1.8em;
                    }
                    /* Color/shape of burger icon bars */
                    .bm-burger-bars {
                        background: ${theme.colors.black};
                    }

                    /* Color/shape of burger icon bars on hover*/
                    .bm-burger-bars-hover {
                        background: ${theme.colors.primary};
                    }

                    /* Position and sizing of clickable cross button */
                    .bm-cross-button {
                        height: 28px;
                        width: 28px;
                    }

                    /* Color/shape of close button cross */
                    .bm-cross {
                        background: #bdc3c7;
                    }
                    .bm-menu-wrap {
                        position: fixed;
                        height: 100%;  
                    }
                    /* General sidebar styles */
                    .bm-menu {
                        background: ${theme.colors.gray[8]};
                        padding: 2.5em 1em 0;
                        font-size: 1.15em;
                    }
                    /* Morph shape necessary with bubble or elastic */
                    .bm-morph-shape {
                        fill: #373a47;
                    }
                    /* Wrapper for item list */
                    .bm-item-list {
                        padding: 0.8em 1em;
                    }
                    /* Individual item */
                    .bm-item {
                        display: inline-block;
                    }
                    /* Styling of overlay */
                    .bm-overlay {
                        background: rgba(0, 0, 0, 0.3);
                    }
                `}
            />

            <div id="outer-container"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    variant: 'layout.root',
                }}>
                <SkipNavLink />

                <Menu isOpen={false} right title="Navigation">
                    <Nav />
                </Menu>

                <Header />

                <SkipNavContent />
                <main id="page-wrap"
                    sx={{
                        width: '100%',
                        flex: '1 1 auto',
                        variant: 'layout.main',
                    }}>

                    {props.children}

                </main>

                <Footer />
            </div>
        </React.Fragment>
    )
}
