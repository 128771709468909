/** @jsx jsx */
import { jsx } from 'theme-ui'
import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import { Box, Flex } from 'rebass'

import { Container } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { LinkExt } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'

const CodeRed = () => (

    <StaticQuery
        query={graphql`
      {
        codered: file(relativePath:  {eq: "code_red_logo.png"}) {
          
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            
          }
        }
      }
    `}
        render={data =>
            <Container>
                <Flex justifyContent="center"
                    sx={{
                        backgroundColor: 'wrapperbox',
                        mb: 5,
                        py: 4
                    }}>
                    <Box width={['full', 4 / 5, 3 / 4]}
                        sx={{
                            textAlign: 'center',
                            px: 4
                        }}>
                        <h2>Register with CodeRed, our emergency communication service.</h2>
                        <p className="text-lg">Register your contact information with the CodeRed database to be informed of all notices, service interruptions, or serious issues concerning your water and sewer. Your information is confidential and will not be distributed for any other purposes.</p>

                        <Box width="280px" sx={{ textAlign: 'center', m: 'auto', py: 2 }}>
                            <Img fluid={data.codered.childImageSharp.fluid} alt="CodeRed logo" />
                        </Box>

                        <p>Rapid Emergency Notification System - <LinkExt to='https://public.coderedweb.com/CNE/en-US/C23BD5D7BDA8'>Register Now</LinkExt></p>
                    </Box>

                </Flex>
            </Container>
        }
    ></StaticQuery>
)

export default CodeRed
