/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Flex } from 'rebass'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { Container } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { ColorMode } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'

import Logo from './Logo'

const LogoHeader = styled(Logo)`
    .logo_symbol{
        fill: ${props => props.theme.colors.primary}
    }
    .logo_text{
        fill: ${props => props.theme.colors.gray[6]}
    }
`

const HeaderNav = styled.ul`
    margin: 0;
    list-style: none;
    display: block;
    text-align: center;
    a{
        color: ${props => props.theme.colors.gray[8]};
        padding: 0.70em 0;
        text-decoration: none;
        &:hover{
           color: ${props => props.theme.colors.black}; 
        }
    }
    li{
        display: inline;
        font-size: 0.95em;
        font-weight: bold;
        margin: 0;
        padding: 0 ${props => props.theme.space[2]};
        text-transform: uppercase;
    }
`

export default props =>
    <Box
        {...props}
        sx={{
            position: 'relative',
            height: 'auto',
        }}
    >
        <Container>
            <Flex flexWrap="wrap" justifyContent="space-between" alignItems="center">
                <Box justifyContent="center" width={['280px']} py={3}>
                    <Link to="/" aria-label="Logo">
                        <LogoHeader fill="currentcolor" className="logo_header"
                        />
                    </Link>
                </Box>
                <Box width={["full", 1 / 2]} sx={{ margin: '0 auto', pb: [2, 0] }}>
                    <HeaderNav>
                        <li>
                            <Link to="faqs">FAQs</Link>
                        </li>
                        <li>
                            <Link to="contact">Contact</Link>
                        </li>
                        <li>
                            <Link to="customer-service/account">My Account</Link>
                        </li>
                    </HeaderNav>
                </Box>

                <Box sx={{ position: 'absolute', right: ['1.15em', '3.4em', '4em'], top: ['4.75em', '1.5em'], color: 'black' }}>
                    <ColorMode />
                </Box>
            </Flex>
        </Container>
    </Box>
