/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { Box, Flex } from 'rebass'

import { Container } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { Button } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'

const CallToAction = () => {
    return (
        <Box
            sx={{
                backgroundColor: 'wrapperbox',
                py: [4, 5],
                textAlign: 'center'
            }}
        >
            <Container>
                <Flex justifyContent="center">
                    <Box width={['full', 4 / 5, 3 / 4]}>
                        <h2>Have questions? We have answers.</h2>
                        <p className="text-lg">We help our customers in any way we can. Just give us a call, and we’ll put you in touch with the appropriate Department Supervisor to get your problems solved.</p>
                    </Box>

                </Flex>

                <Flex flexWrap="wrap" justifyContent="center">
                    <Box >
                        <Link to="contact" sx={{ px: 1 }}>
                            <Button variant="simple" >Contact Us</Button>
                        </Link>
                    </Box>
                    <Box >
                        <Link to="customer-service" sx={{ px: 1 }}>
                            <Button variant="simple">Customer Service</Button>
                        </Link>
                    </Box>
                </Flex>
            </Container>
        </Box >
    )
}

export default CallToAction
