import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import theme from '@giraldomac/gatsby-theme-mmdbase/src/gatsby-plugin-theme-ui'

const MainNav = styled.div`
    margin: 2.5em 0 0 0.5em;
    a{
        color: white;
        display: block;
        text-decoration: none;
        text-align: left;
    }
    ul {
        font-size: 0.95em;
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            font-weight: bold;
        }
    }
    ul li ul li{
        font-weight: initial;
        margin: 0 0 0.25em 0;
        a {
            color: ${theme.colors.gray[5]};
        }
        a:hover {
            color: ${theme.colors.gray[1]};
            transition: color .25s;
        }
    }
    
`


const Nav = () => {
    return (

        <MainNav>
            <ul>
                <li><Link to="/">Home</Link></li>
                <li>
                    <Link to="/about">About</Link>
                    <ul>
                        <li><Link to="/about/departments">Departments</Link></li>
                        <li><Link to="/about/leadership">Leadership</Link></li>
                        <li><Link to="/about/facilities-systems">Facilities & Systems</Link></li>
                    </ul>
                </li>
                <li>
                    <Link to="/customer-service">Customer Service</Link>
                    <ul>
                        <li><Link to="/customer-service/account">Account</Link></li>
                        <li><Link to="/customer-service/information">Information</Link></li>
                    </ul>
                </li>
                <li>
                    <Link to="/faqs">FAQs</Link>
                    <ul>
                        <li><Link to="/faqs/water">Water Related</Link></li>
                        <li><Link to="/faqs/sewer">Sewer Related</Link></li>
                        <li><Link to="/faqs/emergency">Emergency Related</Link></li>
                        <li><Link to="/faqs/meter">Meter Related</Link></li>
                        <li><Link to="/faqs/customer-service">Customer Service</Link></li>
                    </ul>
                </li>
                <li>
                    <Link to="/services">Services</Link>
                    <ul>
                        <li><Link to="/services/water">Water</Link></li>
                        <li><Link to="/services/sewer">Sewer</Link></li>
                        <li><Link to="/services/consultation-design">Consultation/Design</Link></li>
                    </ul>
                </li>
                <li><Link to="/projects">Projects</Link></li>
                <li><Link to="/careers">Careers</Link></li>
                <li>
                    <Link to="/notices">Notices</Link>
                    <ul>
                        <li><Link to="/notices/general">General</Link></li>
                        <li><Link to="/notices/emergencies">Emergencies</Link></li>
                        <li><Link to="/notices/interruptions">Service Interruptions</Link></li>
                    </ul>
                </li>
                <li><Link to="/contact">Contact</Link></li>
                <li><Link to="/terms">Terms and Conditions</Link></li>
            </ul>
        </MainNav>

    )
}

export default Nav
