/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box } from 'rebass'

const ListItem = (props) => {
    return (
        <Box as="li" {...props}
            sx={{
                alignItems: 'center',
                display: 'flex',
                listStyle: 'none',
                justifyContent: 'space-between',
                py: 2,
                borderBottom: '1px solid',
                borderColor: 'gray.2',
                a: {
                    color: 'primary',
                    textDecoration: 'none'
                },
                "a:hover": {
                    color: 'primaryHover',
                    transition: 'all 200ms ease-in'
                },
                svg: {
                    fontSize: 3
                }
            }} />
    )
}

export default ListItem
