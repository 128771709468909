/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box } from 'rebass'

const Alert = ({ props, children }) => {
    return (
        <Box {...props} sx={{
            backgroundColor: 'red.1',
            border: '1px solid',
            borderColor: 'red.4',
            p: 3,
            color: 'red.7'
        }}>
            {children}
        </Box>
    )
}

export default Alert
